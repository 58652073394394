<nb-layout>
	<nb-layout-column>
		<nb-card>
    <nb-card-body>
      <div style="width: calc(100% - 15px);">
        <div class="row">
          <div class="col-lg-6">
            <img src="./assets/images/login_bg.jpg" alt="Img" width="100%" />
          </div>
          <div class="col-lg-6">
            <div class="chnagepassword-parkign-logo">
              <img src="./assets/images/logo.svg" alt="Car" />
            </div>
            <div class="chnagepassword-title">
              Change Password
            </div>
            <div class="chnagepassword-desc"></div>
            <form class="chnagepassword-form" #f="ngForm" (ngSubmit)="SubmitPassword(f)" novalidate>
              <div class="row">
                <div class="col-12">
                  <label>New Passowrd</label>
                  <input type="password" class="form-control" id="password" placeholder="Enter New Password" [(ngModel)]="password_form.new_password" #password="ngModel" minlength="5" maxlength="50" required name="new_password">
                  <span *ngIf="password_form.new_password == '' && checkRequiredField">New Password is required field.</span>
                  <app-passoword-strength-bar [passwordToCheck]="password_form.new_password" [barLabel]="barLabel"></app-passoword-strength-bar> 
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>Confirm Password</label>
                  <input type="password" nbInput placeholder="Enter confirm password" [(ngModel)]="password_form.confirm_password" name="confirm_password" required />
                  <span *ngIf="password_form.confirm_password == '' && checkRequiredField">Confirm Password is required field.</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 remember-text"></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button nbButton class="btn-chnagepassword" [disabled]="buttonloader">Change Password</button>
                </div> 
              </div>
            </form>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  </nb-layout-column>
</nb-layout>