<nb-card>
  <nb-card-header>Change Password</nb-card-header>
  <nb-card-body>
    <form autocomplete="off" name="password_form">
    <div class="profile-box">
      <div class="row">
        <div class="col-12 profile-title">
          <img src="./assets/svg/password_ic.svg" alt="Img" /> Current Password
        </div>
        <div class="col-12 profile-desc">
          <input type="password" class="form-control" placeholder="Enter Current Password" [(ngModel)]="passwordDetails.password" name="password" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 profile-title">
          <img src="./assets/svg/password_ic.svg" alt="Img" /> New Password
        </div>
        <div class="col-12 profile-desc">
          <!-- <input type="password" placeholder="Enter New Password" [(ngModel)]="passwordDetails.newpassword" /> -->
          <input type="password" class="form-control" id="password" placeholder="Enter New Password" [(ngModel)]="passwordDetails.newpassword" #password="ngModel" minlength="5" maxlength="50" required name="newpassword">
          <app-passoword-strength-bar [passwordToCheck]="passwordDetails.newpassword" [barLabel]="barLabel"></app-passoword-strength-bar> 
        </div>
      </div>
      <div class="row">
        <div class="col-12 profile-title">
          <img src="./assets/svg/password_ic.svg" alt="Img" /> Confirm Password
        </div>
        <div class="col-12 profile-desc">
          <input type="password"class="form-control"  placeholder="Enter Confirm Password" [(ngModel)]="passwordDetails.confirm_password" name="confirm_password" />
        </div>
      </div>
    </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="col-12 text-right">
        <button class="btn-profile" (click)="saveData()">Save</button>&nbsp;&nbsp;
        <button class="btn-profile" (click)="dismiss()">Close</button>
      </div>
    </div>
   
  </nb-card-footer>
</nb-card>
